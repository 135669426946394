import { notification } from "antd";
import { CronMapperForDayByDay } from "./CronMapper";
import { DurationMapper } from "./DurationMapper";

export const WEEK_DAYS = [
  { label: "Pazartesi", value: 0 },
  { label: "Salı", value: 1 },
  { label: "Çarşamba", value: 2 },
  { label: "Perşembe", value: 3 },
  { label: "Cuma", value: 4 },
  { label: "Cumartesi", value: 5 },
  { label: "Pazar", value: 6 },
];

export const transformDevicesToResourcesArray = (devices) => {
  const res = devices.map((d) => {
    if (!d.Device) return;
    const valves = JSON.parse(d.Device.valves);
    const zonesArr = Object.keys(valves)
      .map((k) => ({
        serial: d.Device.serial,
        id: d.Device.serial + "_" + k,
        name: valves[k].name,
        on: valves[k].state === "on",
      }))
      .filter((v) => v.on);

    return {
      serial: d.Device.serial,
      name: d.model === "BL" ? d.sid.slice(-5) : d.Device.name,
      id: d.Device.id,
      expanded: true,
      cssClass: "font-bold",
      children: zonesArr,
      readOnly: d.model === "BL",
    };
  });

  console.log(res);

  return res;
};

export const transformDeviceSerial = (deviceSerial) => {
  return deviceSerial.substring(0, deviceSerial.length - 2);
};
export const transformPlanId = (deviceSerial, planId) => {
  return planId.substring(deviceSerial.length + 1, planId.length - 2);
};

export const planSuccessMessage = (text) => {
  notification.success({
    message: "Başarılı",
    description: text,
    placement: "topRight",
  });
};

export const planErrorMessage = (text) => {
  notification.error({
    message: "Hata",
    description: text,
    placement: "topRight",
  });
};

const getDateAfter24Hours = () => new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

const getISODateStringFromDate = (date) => date.toISOString().split("T")[0]

const composeDateString = ({ dateString, timeString }) => dateString + "T" + timeString + ":00"

export const transformPlanResourceToEvent = (planTimes, resource) => {
  return planTimes
    .filter((time) => time.e !== time.s)
    .map((time) => {
      let startDateTime;
      let endDateTime;

      /**
       * When the start time falls on the previous day
       * start time will be "" (empty string)
       * we should set it to 00:00 same day 
       * so that scheduler can interpret the event correctly 
       */
      if (time.s === "") {
        startDateTime = composeDateString({
          dateString: getISODateStringFromDate(new Date()),
          timeString: "00:00",
        })
      } else {
        startDateTime = composeDateString({
          dateString: getISODateStringFromDate(new Date()),
          timeString: time.s,
        })
      }

      /**
       * When the end time falls on the next day
       * end time will be "" (empty string)
       * we should set it to 00:00 next day 
       * so that scheduler can interpret the event correctly 
       */
      if (time.e === "") {
        const dateFrom24Hours = getDateAfter24Hours()
        endDateTime = composeDateString({
          dateString: getISODateStringFromDate(dateFrom24Hours),
          timeString: "00:00",
        })
      } else {
        endDateTime = composeDateString({
          dateString: getISODateStringFromDate(new Date()),
          timeString: time.e,
        })
      }

      return {
        id: resource.serial + "_" + time.id + "_" + time.v,
        resource: resource?.serial + "_" + time.v,
        start: startDateTime,
        end: endDateTime,
        text: "",
        moveVDisabled: true,
        readOnly: resource.model === "BL",
      }
    });
};

export const createPlanRequestBuilder = (args, selectedDay, planName) => {
  console.log('Event args', args);
  return {
    deviceSerial: args.resource.split("_").slice(0, -1).join("_"),
    name: planName,
    cron: CronMapperForDayByDay({
      start: args.start,
      selectedDay: selectedDay,
    }),
    status: "active",
    durations: DurationMapper({
      valve: args.resource.split("_").slice(-1)[0],
      start: args.start,
      end: args.end,
      totalSubDeviceLength: 9,
    }),
    allowConflicts: true,
  };
};

export const updatePlanRequestBuilder = (args, selectedDay, planName) => {
  return {
    deviceSerial: transformDeviceSerial(args.e.data.resource),
    planId: transformPlanId(
      transformDeviceSerial(args.e.data.resource),
      args.e.data.id
    ),
    name: planName,
    status: "active",
    cron: CronMapperForDayByDay({
      start: args.newStart,
      selectedDay: selectedDay,
    }),
    durations: DurationMapper({
      valve: args.e.data.resource[args.e.data.resource.length - 1],
      start: args.newStart,
      end: args.newEnd,
      totalSubDeviceLength: 8,
    }),
    allowConflicts: true,
  };
};

export const deletePlanRequestBuilder = (args) => {
  return {
    deviceSerial: transformDeviceSerial(args.e.data.resource),
    planId: transformPlanId(
      transformDeviceSerial(args.e.data.resource),
      args.e.data.id
    ),
  };
};

export const generateSelectedDays = (e) => {
  return e.target.checked ? [0, 1, 2, 3, 4, 5, 6] : [];
};
