import { useContext } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { SearchOutlined } from "@ant-design/icons";
import { BsCloudRainHeavyFill, BsWind, BsFillSunFill, BsStarFill, BsPinMap } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import PropTypes from "prop-types";
import { notification, Select, Switch } from "antd"
import tr from '../i18n'
import { AppContext } from '../AppContext'
const { Option } = Select;

const GardenListSearch = (props) => {
  const { fetchAllGardens } = useContext(AppContext);

  const getDistinctOptimizations = (obj) => {
    const s = new Set()
    Object.values(obj).map(vs => vs.map(v => s.add(v)))

    return Array.from(s)
  }

  const getIcon = (name) => {
    switch (name) {
      case 'rain':
        return <BsCloudRainHeavyFill />

      case 'wind':
        return <BsWind />

      case 'sun':
        return <BsFillSunFill />

      default:
        return ''
    }
  }

  const getActiveWateringClass = (garden) => {
    if (garden.devices.every(d => d.plans.filter(p => p.status === 'active').length === 0)) {
      return 'bg-gray-200'
    }

    if ((garden.next24h && garden.next24h.length === 0) || garden.wateringStatus === 'disabled') {
      return 'bg-yellow-100'
    }

    return 'bg-gray-50'
  }

  const wateringStatus = useMutation(({ status, gardenId }) => {
    return axios.put(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden",
      {
        id: gardenId,
        wateringStatus: status,
      }
    )
  }, {
    onSuccess: (
      data,
      variables
    ) => {
      notification.success({
        message: tr("Changes saved successfully"),
        description: "Garden: " + variables.gardenId,
      })
    },
    onError: (error) => {
      notification.error({
        message: tr("Something went wrong while saving changes"),
      })
    },
    onSettled: () => {
      fetchAllGardens({
        background: true,
        force: true
      })
    }
  })

  return (
    <div className="flex flex-col rounded-md bg-white text-gray-800  border ">
      <div className="p-4  relative">
        <div className="absolute pl-3 text-gray-400" style={{ paddingTop: 6 }}>
          <SearchOutlined />
        </div>
        <input
          onChange={props.onSearch}
          type="text"
          className="rounded-md px-4 py-2 border w-full pl-8"
          placeholder="Park/Bahçe Arama"
        />
        <div className="flex flex-col gap-1 pt-2">

          <Select mode="tags" placeholder={tr("Filter gardens by tags")} onChange={props.onTagsChange}>
            {props.tags.map(t => <Option key={t.id}>{t.name}</Option>)}
          </Select>
        </div>
      </div>
      <div
        className="flex flex-col overflow-y-scroll w-full md:w-72"
        style={{ height: 528 }}
      >
        {props.loading && <div
          className="flex flex-col gap-3 p-4 animate-pulse"
        >
          <div className=" bg-gray-200 w-full h-10 rounded-md"></div>
          <div className=" bg-gray-200 w-full h-10 rounded-md"></div>
        </div>}
        {!props.loading && props.parksData.map((p) => (
          <div
            key={p?.geoJson?.properties?.MAHAL_ID}
            onClick={() => props.onEdit(p)}
            className={` relative px-4 py-2 w-full flex flex-row justify-between hover:bg-gray-100 group ${getActiveWateringClass(p)} cursor-pointer border-b `}
          >
            <div className="m-0 font-medium flex flex-col">
              <div className="flex gap-2 items-center">
                {/* {p.next24h && p.next24h.length !== 0 && <div className="text-xl text-blue-500"><BiDroplet /></div>} */}
                <div className="text-xs text-gray-700 flex flex-col">
                  {p.tags && p.tags.includes('gardens:favori') && <BsStarFill />}
                </div>
                <div className="text-xs text-red-500 flex flex-col">
                  {p.tags && p.tags.includes('gardens:ozel') && <FiAlertTriangle />}
                </div> {p.geoJson.properties.MAHAL_ADI}
              </div>
              <div className="font-light flex gap-2 items-center pt-2 text-blue-500">

                {p.nextOptimizations && getDistinctOptimizations(p.nextOptimizations).map(opt => <div className="">{getIcon(opt)}</div>)}

              </div>
            </div>
            <div className=" flex items-center gap-1 text-xs p-1 rounded-md bg-white opacity-0 group-hover:opacity-100 invisible md:visible">
              <Switch
                size="small"
                checked={p.wateringStatus === 'active'}
                onChange={(value, e) => {
                  e.stopPropagation()
                  wateringStatus.mutate({
                    status: value ? 'active' : 'disabled',
                    gardenId: p.id
                  })
                }}
              // checkedChildren="Aktif"
              // unCheckedChildren="Pasif"
              />

              <button
                onClick={(e) => {
                  props.makeParkVisible(p)
                  e.stopPropagation()
                }}
                className=" flex items-center p-2 rounded-md hover:bg-gray-100 border"
              >
                <BsPinMap />

              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

GardenListSearch.propTypes = {
  onSearch: PropTypes.func,
  filteredParks: PropTypes.array,
  parksData: PropTypes.object,
  makeParkVisible: PropTypes.func,
  onEdit: PropTypes.func,
  loading: PropTypes.bool,
  tags: PropTypes.array,
  onTagsChange: PropTypes.func
};

export default GardenListSearch;
