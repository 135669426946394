import { Navigate, Route, Routes } from "react-router-dom";
import "./App.less";
import Login from "./containers/Login";
import ControlCenter from "./containers/ControlCenter";
import Dashboard from "./containers/Dashboard";
import Scheduler from "./containers/Scheduler";
import GardensList from "./containers/GardensList";
import UserManagement from "./system-management/UserManagement";
import { AppContextProvider } from "./AppContext";
import DeviceManagement from "./containers/DeviceManagement";
import UserAccess from "./system-management/UsersAccess";
import GardenAccess from "./system-management/GardenAccess";
import Devices from "./system-management/Devices";
import Tags from "./system-management/Tags";
import Audit from "./system-management/Audit";
import SignalMap from "./components/SignalMap";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/slices";
import { QueryClient, QueryClientProvider } from "react-query";
import { GiCardboardBox } from "react-icons/gi";

const queryClient = new QueryClient();

const store = configureStore({
  reducer: rootReducer,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AppContextProvider>
          <div className="App antialiased">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="control-center" element={<ControlCenter />}>
                <Route path="gardens" element={<GardensList />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="gardens/:id" element={<Scheduler />} />
                <Route path="users" element={<UserManagement />}>
                  <Route path="user-access" element={<UserAccess />} />
                  <Route path="garden-access" element={<GardenAccess />} />
                  <Route path="tags" element={<Tags />} />
                  <Route path="audit" element={<Audit />} />
                  <Route path="devices/:id" element={<Devices />} />
                  <Route index element={<Navigate to="garden-access" />} />
                </Route>
                <Route path="devices" element={<DeviceManagement />} />
                <Route index element={<Navigate to="gardens" />} />

              </Route>
              <Route path="signal-map" element={<SignalMap />}>

              </Route>
              <Route index element={<Login />} />
              <Route path="*" element={<div className="h-screen w-screen flex items-center justify-center">
                <div className="flex flex-col gap-2 items-center">
                  <div className="text-5xl flex"><GiCardboardBox /> 404</div>
                  Page not found
                </div>
              </div>} />
            </Routes>
          </div>
        </AppContextProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
