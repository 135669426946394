import { Select, Input, Button, notification } from 'antd';
import axios from "axios";
import { SafetyCertificateOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons'
import { NavLink, Outlet } from 'react-router-dom'
import './UserManagement.css'
import { useContext, useState } from 'react';
import { validEmail, validPhoneNumber } from '../utils/RegExUtility'
import { hasAccess, ROLES } from '../utils/AccessLevels'
import { AppContext } from '../AppContext'
import { useQueryClient } from 'react-query'
import tr from '../i18n'
import InfoLabel from '../components/InfoLable';

const UserManagement = () => {
    const [invitationEmail, setInvitationEmail] = useState(null)
    const [invitationRole, setInvitationRole] = useState(null)
    const [invitationName, setInvitationName] = useState(null)
    const queryClient = useQueryClient()

    // const [invitationPhone, setInvitationPhone] = useState(null)
    const { role } = useContext(AppContext)

    const sendInvitation = async () => {
        if (!invitationEmail || !invitationRole) return;

        if (!validEmail.test(invitationEmail) && !validPhoneNumber.test(invitationEmail)) {
            return notification.error({
                message: tr("Check the information you entered"),
                description: tr(`Faulty phone number`),
                placement: "topRight",
            });
        }

        const res = await axios.post(process.env.REACT_APP_GARDENER_API_END_POINT + '/user/create', {
            emailOrPhone: "90" + invitationEmail,
            role: invitationRole,
            name: invitationName,
        })

        if (res.data.success) {
            setInvitationEmail(null)
            setInvitationName(null)
            setInvitationRole(null)
            notification.success({
                message: tr("User created successfully"),
                description: tr("Invitation has been sent to the user"),
                placement: "topRight"
            })
            queryClient.invalidateQueries('FetchAllUsers')
        } else {
            notification.error({
                message: tr("User creation failed"),
                description: res.data.error,
                placement: "topRight"
            })
        }
    }

    const onInvitationRoleChange = (e) => {
        console.log(e);
        setInvitationRole(e)
    }

    const onInvitationEmailChange = (e) => {
        const value = e.target.value
        setInvitationEmail(value)
    }

    const onInvitationNameChange = (e) => {
        const value = e.target.value
        setInvitationName(value)
    }

    // const onInvitationPhoneChange = (e) => {
    //     const value = e.target.value
    //     setInvitationPhone(value)
    // }


    return <>
        <div className="p-4 bg-white bg-opacity-20 rounded-md text-white">
            <div>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-4">

                        {hasAccess(ROLES.REGIONAL_MANAGER, role) && <div className="p-5 bg-white border text-gray-800 rounded-md flex flex-col gap-4">
                            <div>

                                <span className="block font-bold">Kullanıcı Ekle</span>
                                <InfoLabel> Davet emaili göndererek kullanıcı ekle</InfoLabel>
                            </div>
                            <div className="flex flex-row gap-2 flex-wrap">
                                <Input value={invitationName} type="text" placeholder={tr("Name")} className="" style={{ width: 250, borderRadius: 5 }} prefix={<UserOutlined />} onChange={onInvitationNameChange} />
                                <Input value={invitationEmail} type="text" placeholder="5xx xxx xx xx" className="" style={{ width: 250, borderRadius: 5 }} prefix={<div className='flex items-center gap-1'><PhoneOutlined /> +90</div>} onChange={onInvitationEmailChange} />
                                {/* <Input type="text" placeholder="Phone" className="" style={{ width: 350, borderRadius: 5 }} suffix={<SafetyCertificateOutlined className="" />} onChange={onInvitationPhoneChange} /> */}
                                <Select value={invitationRole} dropdownStyle={{ borderRadius: 5 }} suffixIcon={<SafetyCertificateOutlined />} className="rounded-md" style={{ width: 180 }} placeholder="Kullanıcı rolü" onChange={onInvitationRoleChange}>
                                    {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <Select.Option value="technical_admin">Teknik Yönetici</Select.Option>}
                                    {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <Select.Option value="regional_manager">Bölge Yöneticisi</Select.Option>}
                                    <Select.Option value="field_manager">Park Yöneticisi</Select.Option>
                                    <Select.Option value="pro_user">Profesyonel</Select.Option>
                                    <Select.Option value="end_user">Son kullanıcı</Select.Option>
                                </Select>
                                <Button style={{ borderRadius: 5 }} onClick={sendInvitation}>Davetiye gönder</Button>
                            </div>
                        </div>}
                    </div>
                    <div className=" text-gray-80 flex flex-col">
                        <div className="flex flex-row gap-1 flex-wrap mb-2 lg:mb-0">
                            {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <NavLink to="user-access" className="tab-handle">
                                Kullanıcılar
                                <span className=" text-xs text-gray-500 hidden md:block">Kullanıcı Erişimleri Yönet</span>
                            </NavLink>}
                            <NavLink to="garden-access" className="tab-handle">
                                Parklar/Bahçeler
                                <span className="block text-xs text-gray-500 hidden md:block">Park/Bahçe Erişimleri Yönet</span>
                            </NavLink>
                            {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <NavLink to="devices/0" className="tab-handle">
                                Cihazlar
                                <span className="block text-xs text-gray-500 hidden md:block">Cihazları yönet</span>
                            </NavLink>}
                            {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <NavLink to="tags" className="tab-handle">
                                Etiketler
                                <span className="block text-xs text-gray-500 hidden md:block">Etiketleri yönet</span>
                            </NavLink>}
                            {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <NavLink to="audit" className="tab-handle">
                                Geçmiş
                                <span className="block text-xs text-gray-500 hidden md:block">Kullanım kayıtları</span>
                            </NavLink>}
                        </div>
                        <div>
                            <Outlet />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default UserManagement
