import { useEffect, useState, useContext, useRef } from "react";
import { Avatar, Input, Transfer, notification, Select, Button, Popover, Modal } from "antd";
import {
    SearchOutlined,
    CheckCircleFilled
} from "@ant-design/icons";
import { BsStarFill } from "react-icons/bs";
import { RxDragHandleDots2 } from "react-icons/rx";
import { FiAlertTriangle } from "react-icons/fi";
import axios from "axios";
import { transformTurkish } from "../utils/RegExUtility"
import { AppContext } from '../AppContext'
import { hasAccess, ROLES } from '../utils/AccessLevels'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import tr from '../i18n'
import { MapContainer, TileLayer, useMap, FeatureGroup, GeoJSON } from "react-leaflet";
import { MAP_ATTRIBUTION_URL, BASE_MAPS, GeoJSON_STYLE } from "../utils/MapUtils";
import { EditControl } from "react-leaflet-draw"
import InfoLabel from "../components/InfoLable";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Option } = Select

const GardenAccess = () => {
    const [userSearchQuery, setUserSearchQuery] = useState("")
    const [selectedGarden, setSelectedGarden] = useState(null)
    const [targetKeys, setTargetKeys] = useState([]);
    const [removeConfirmationVisible, setRemoveConfirmationVisible] = useState(false)

    const [loading, setLoading] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const { gardens, loadingGardens, fetchAllGardens, role, tenantSettings } = useContext(AppContext);
    const queryClient = useQueryClient()

    const [managedGardens, setManagedGardens] = useState([]);

    useEffect(() => {
        setManagedGardens([...gardens])
        console.log('c:', [...gardens]);
        setGardenActivationTargetKeys([...gardens].filter(g => g.wateringStatus !== 'active').map(g => g.id))
    }, [gardens])

    const tags = useQuery('GardenTags', async () => {
        const res = await axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/tag?resource=gardens')
        return res.data.tags
    })


    useEffect(() => {
        fetchAllGardens()
    }, [])

    /**
     * Garden devices
     */
    const gardenDevices = useQuery(["GardenDevices", selectedGarden], async () => {
        const res = await axios.get(
            process.env.REACT_APP_PLANNER_API_END_POINT +
            "/garden/" +
            selectedGarden.id +
            "/device"
        )

        console.log(res);
        return res.data.devices
    }, { enabled: !!selectedGarden });

    /**
     * Garden devices + devices available for assignment
     */
    const gardenDevicesAvailable = useQuery(["GardenDevicesAvailable", selectedGarden], async () => {
        const res = await axios.get(
            process.env.REACT_APP_PLANNER_API_END_POINT +
            "/garden/" +
            selectedGarden.id +
            "/device/available"
        )
        return res.data.devices
    }, { enabled: !!selectedGarden });

    useEffect(() => {
        if (!gardenDevices.data) return;

        setTargetKeys(gardenDevices.data.map(d => d._id))
    }, [gardenDevices.data])


    const onTargetsChange = async (nextTargetKeys, direction, moveKeys) => {
        console.log(nextTargetKeys, direction, moveKeys);
        const gardenId = selectedGarden.id
        if (direction === 'right') {
            // adding gardens : moveKeys
            try {
                setLoading(true)

                const res = await axios.put(`${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/device`, {
                    gardenId,
                    deviceIds: moveKeys
                })
                console.log(res);
                if (res.data.success) {
                    notification.success({
                        message: tr("Device assignments successful"),
                        description: `${res.data.devices.length} ${tr('devices assigned')}`,
                        placement: "topRight",
                    });

                    // if successful set target keys
                    //setTargetKeys(nextTargetKeys)
                    queryClient.invalidateQueries(['GardenDevices'])
                } else {
                    notification.error({
                        message: tr("Something went wrong"),
                        description: tr(`While assigning devices`),
                        placement: "topRight",
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)

            }
        }

        if (direction === 'left') {
            // removing gardens : moveKeys

            try {
                setLoading(true)
                const res = await axios.delete(`${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/device`, {
                    data: {
                        gardenId,
                        deviceIds: moveKeys
                    }
                })

                if (res.data.success) {
                    notification.success({
                        message: tr("Device removal successful"),
                        description: `${res.data.devices.length} ${tr("devices removed from garden")}`,
                        placement: "topRight",
                    });

                    // if successful set target keys
                    //setTargetKeys(nextTargetKeys)
                    queryClient.invalidateQueries(['GardenDevices'])
                } else {
                    notification.error({
                        message: tr("Something went wrong"),
                        description: tr(`While removing devices`),
                        placement: "topRight",
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)

            }
        }

    };

    const onGardenClick = (garden) => {
        setSelectedGarden(garden)
        setEditableGarden({
            name: garden.name,
            id: garden.id,
            geoJson: garden.geoJson
        })
    }

    const filterOption = (input, item) => {
        console.log(input, item);
        return RegExp(`.*${transformTurkish(input)}.*`, "gi").test(
            transformTurkish(item.Device.name))
    }

    const onUserSearch = (e) => {
        console.log(e.target.value);
        const value = e.target.value
        setUserSearchQuery(value)
    }

    const onTagsChange = (e) => {
        if (!e) return;

        setSelectedTags(e)
    }

    const getFilteredGardens = () => {
        let filteredGardens = managedGardens
        if (userSearchQuery && userSearchQuery.trim().length > 1) {
            filteredGardens = filteredGardens.filter((p) => RegExp(`.*${transformTurkish(userSearchQuery.trim())}.*`, "gi").test(
                transformTurkish(p.geoJson.properties.MAHAL_ADI)
            ) || RegExp(`.*${userSearchQuery.trim()}.*`, "gi").test(p.id))
        }

        if (selectedTags.length > 0) {
            filteredGardens = filteredGardens.filter((p) => p.tags && selectedTags.every(t => p.tags.includes(t)))
        }

        return filteredGardens;
    }

    const [baseMap, setBaseMap] = useState(null)
    const onBaseMapChange = (m) => {
        const selected = BASE_MAPS.find(b => b.label === m)
        setBaseMap(selected)
    }

    useEffect(() => {
        if (tenantSettings?.defaultBaseMap) {
            setBaseMap(tenantSettings?.defaultBaseMap)
        }
    }, [tenantSettings?.defaultBaseMap])

    const [newGardenName, setNewGardenName] = useState(null);
    const [newGardenId, setNewGardenId] = useState(null);
    const [newGardenPolygon, setNewGardenPolygon] = useState(null);
    const [editableGarden, setEditableGarden] = useState(null);

    const onPolygonChange = (e) => {
        if (editableGarden) {
            setEditableGarden(prev => ({
                ...prev,
                geoJson: {
                    ...prev.geoJson,
                    geometry: e
                }
            }))
        } else {
            setNewGardenPolygon(e)
        }

    }

    const createGarden = useMutation(() => {
        return axios.post(
            `${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/`,
            {
                id: newGardenId,
                name: newGardenName.trim(),
                geoJson: {
                    type: 'Feature',
                    properties: {
                        MAHAL_ID: newGardenId,
                        MAHAL_ADI: newGardenName.trim()
                    },
                    geometry: newGardenPolygon
                }
            }
        )
    }, {
        onSuccess: () => {
            fetchAllGardens({
                force: true,
                background: true
            })
            notification.success({
                message: tr('Garden created successfully')
            })
        },
        onError: () => {
            notification.error({
                message: tr('Failed to create garden')
            })
        }
    })

    const updateGarden = useMutation((garden) => {
        if (garden.geoJson.geometry &&
            garden.geoJson.geometry.coordinates &&
            garden.geoJson.geometry.coordinates.length === 0) {
            notification.error({
                message: tr('Map geometry is empty')
            })
            return Promise.reject(new Error('Map geometry is empty'));
        }

        return axios.put(
            `${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/`,
            {
                id: garden.id,
                name: garden.name,
                geoJson: {
                    type: 'Feature',
                    properties: {
                        MAHAL_ID: garden.id,
                        MAHAL_ADI: garden.name
                    },
                    geometry: garden.geoJson.geometry
                }
            }
        )
    }, {
        onSuccess: () => {
            fetchAllGardens({
                force: true,
                background: true
            })
            notification.success({
                message: tr('Garden updated successfully')
            })
        },
        onError: () => {
            notification.error({
                message: tr('Failed to update the garden')
            })
        }
    })

    const onGardenCreate = async () => {
        if (!newGardenName || !newGardenPolygon) {
            return notification.error({
                message: tr('Garden ID and Name is required')
            })
        };

        console.log(newGardenId, newGardenName, newGardenPolygon);

        if (newGardenPolygon.coordinates.length === 0) {
            notification.error({
                message: tr('Map area polygon is required')
            })
            return;
        }

        await createGarden.mutateAsync()

        setNewGardenId(null)
        setNewGardenName(null)
        setNewGardenPolygon(null)
    }

    const removeGarden = useMutation((id) => {
        return axios.delete(
            `${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/${id}`
        )
    }, {
        onSuccess: () => {
            fetchAllGardens({
                force: true,
                background: true
            })
            notification.success({
                message: tr('Garden removed successfully')
            })

            setSelectedGarden(null)
            setRemoveConfirmationVisible(false)
        },
        onError: () => {
            notification.error({
                message: tr('Failed to remove garden')
            })
        }
    })

    const updateGardenOrder = useMutation((gardenIds) => {
        return axios.put(process.env.REACT_APP_PLANNER_API_END_POINT + "/garden/order",
            {
                order: gardenIds
            }
        )
    }, {
        onSuccess: () => {
            console.log('ordered');
            fetchAllGardens({
                force: true,
                background: true
            })
        }
    })

    const handleDragEnd = (result) => {
        if (!result.destination) return; // Return if dropped outside the list

        const updatedItems = [...managedGardens];
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);

        setManagedGardens(updatedItems);
        console.log(updatedItems.map(ud => ud.id));
        updateGardenOrder.mutate(updatedItems.map(ud => ud._id))
    };

    const [gardenActivationTargetKeys, setGardenActivationTargetKeys] = useState([]);
    const [commonWateringStatus, setCommonWateringStatus] = useState(null);
    const [multiGardenActivation, setMultiGardenActivation] = useState(false);

    const updateGardenWateringStatus = useMutation(({ gardenId, wateringStatus }) => {
        return axios.put(
            `${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/`,
            {
                id: gardenId,
                wateringStatus,
            }
        )
    }, {
        onSuccess: (data, variables) => {

            notification.success({
                message: tr('Garden updated successfully'),
                description: variables.gardenId
            })
        },
        onError: (error, variables) => {
            notification.error({
                message: tr('Failed to update the garden'),
                description: variables.gardenId
            })
        }
    })

    const updateManyWateringStatus = async (gardenIds, wateringStatus) => {

        console.log(gardenIds, wateringStatus);

        for (const gId of gardenIds) {
            await updateGardenWateringStatus.mutateAsync({
                gardenId: gId,
                wateringStatus,
            })
        }

        fetchAllGardens({
            force: true,
            background: true
        })
    }

    const filteredGardens = () => {
        if (selectedTags.length > 0) {
            return gardens.filter(g => selectedTags.every(t => g.tags && g.tags.includes(t)))
        }

        return gardens
    }

    const gardenActivationFilterOption = (input, item) => {
        return RegExp(`.*${transformTurkish(input)}.*`, "gi").test(
            transformTurkish(item.geoJson.properties.MAHAL_ADI))
    }

    const onGardenActivationTargetsChange = async (nextTargetKeys, direction, moveKeys) => {
        console.log(nextTargetKeys, direction, moveKeys);
        const userId = null
        // = selectedUser.id
        if (direction === 'right') {
            // adding gardens : moveKeys
            try {
                setLoading(true)
                setGardenActivationTargetKeys(nextTargetKeys)
                updateManyWateringStatus(moveKeys, 'disabled')
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)

            }

        }

        if (direction === 'left') {
            // removing gardens : moveKeys

            try {
                setLoading(true)
                setGardenActivationTargetKeys(nextTargetKeys)
                updateManyWateringStatus(moveKeys, 'active')
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)

            }
        }

    };

    const onGardenSearch = (dir, value) => { };

    return <>
        <div className="bg-white border text-gray-800 rounded-md flex flex-row rounded-tl-none">
            <div className="flex flex-col w-1/4" style={{ height: 850 }}>
                <div className="p-4 border-b relative">
                    <Input style={{ borderRadius: 5 }} onChange={onUserSearch} prefix={<SearchOutlined />} placeholder="Park/Bahçe arama" allowClear />
                    <div className="flex flex-col gap-1 pt-2">
                        <InfoLabel>{tr("Filter gardens by tags")}</InfoLabel>
                        <Select mode="tags" placeholder={tr("Filter gardens by tags")} onChange={onTagsChange}>
                            {tags.data && tags.data.map(t => <Option key={t.id}>{t.name}</Option>)}
                            {tags.isLoading && <Option key={null}>Loading...</Option>}
                        </Select>
                    </div>
                </div>
                {loadingGardens && <><div className="p-2 animate-pulse flex flex-row">
                    <div className="bg-gray-200 h-5 w-5 mr-2 rounded-full"></div>
                    <div className="bg-gray-200 h-5 w-full rounded-md"></div>
                </div>
                    <div className="p-2 animate-pulse flex flex-row">
                        <div className="bg-gray-200 h-5 w-5 mr-2 rounded-full"></div>
                        <div className="bg-gray-200 h-5 w-full rounded-md"></div>
                    </div></>}
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div className=" w-full h-full overflow-auto" {...provided.droppableProps} ref={provided.innerRef}>
                                {!loadingGardens && getFilteredGardens().map((garden, i) =>
                                    <Draggable key={garden.id} draggableId={garden.id} index={i}>
                                        {(provided) => (
                                            <div onClick={() => onGardenClick(garden)}
                                                className="flex flex-row gap-4 items-center hover:bg-gray-100 p-4 border-b cursor-pointer"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <div className=" flex gap-2 items-center">
                                                    <div><RxDragHandleDots2 /></div>
                                                    <div className="text-xs flex flex-col">
                                                        {garden.tags && garden.tags.includes('gardens:favori') && <BsStarFill />}
                                                    </div>
                                                    <div className="text-xs flex flex-col text-red-500">
                                                        {garden.tags && garden.tags.includes('gardens:ozel') && <FiAlertTriangle />}
                                                    </div>
                                                    <span className="block font-bold">{garden.name}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className=" border-l flex flex-col w-full overflow-auto">
                {selectedGarden && <div className="flex flex-row gap-4 items-center p-4 border-b w-full">
                    <div className="">
                        <Avatar>{selectedGarden.id}</Avatar>
                    </div>
                    <div className="">
                        <div className="block font-bold text-lg">{selectedGarden.name}</div>
                        <div className="text-xs flex items-center gap-1">
                            <div>{selectedGarden.address}</div>{" "}
                            <div className="text-green-brand flex items-center">
                                <CheckCircleFilled />
                            </div>
                        </div>
                    </div>
                    {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <div className="flex flex-row-reverse">
                        <Popover placement="bottom" visible={removeConfirmationVisible} onVisibleChange={(v) => setRemoveConfirmationVisible(v)} content={
                            <div className="p-3 flex flex-col items-center">
                                {tr('Are you sure you want to remove this garden?')}
                                <div>
                                    <Button type="link" onClick={() => { removeGarden.mutate(selectedGarden.id) }}>Evet</Button> <Button type="link" onClick={() => setRemoveConfirmationVisible(false)}>Hayır</Button>
                                </div>
                            </div>} trigger="click">
                            <Button size="small" type="link" danger onClick={() => setRemoveConfirmationVisible(true)}>{tr("Delete the garden")}</Button>
                        </Popover>
                    </div>}
                </div>}
                <div className="flex flex-col gap-4 p-4 px-6 flex-wrap">
                    <div className=" p-4 rounded-md border flex flex-col gap-2">
                        <InfoLabel>{tr("Update watering status for multiple parks")}</InfoLabel>
                        <div>
                            <Button onClick={() => setMultiGardenActivation(true)}>{tr("Update watering status")}</Button>
                        </div>
                    </div>
                    <Modal title={tr("Update watering status for multiple parks")}
                        visible={multiGardenActivation}
                        onCancel={() => setMultiGardenActivation(false)}
                        onOk={() => setMultiGardenActivation(false)}
                        width={'100vw'}>

                        <div className="flex flex-col">
                            <div className="">


                                <Transfer
                                    dataSource={filteredGardens()}
                                    showSearch
                                    filterOption={gardenActivationFilterOption}
                                    targetKeys={gardenActivationTargetKeys}
                                    onChange={onGardenActivationTargetsChange}
                                    onSearch={onGardenSearch}
                                    render={(item) => item.name}
                                    rowKey={(r) => r.id}
                                    listStyle={{ width: 'auto', height: 700, borderRadius: 5 }}
                                    locale={{ itemUnit: 'Park', itemsUnit: 'Parklar' }}
                                    titles={[
                                        <div className="text-gray-800 font-bold text-xs">
                                            Aktif
                                            <span className="text-gray-500 text-xs block font-normal">
                                                Aktif parklar
                                            </span>
                                        </div>,
                                        <div className="text-gray-800 font-bold text-xs">
                                            Engelli
                                            <span className="text-gray-500 text-xs block font-normal">
                                                Engelli parkları
                                            </span>
                                        </div>,
                                    ]}
                                />
                            </div>

                            {/* <div className="flex gap-3 p-3 items-center bg-gray-100 border-t">
                            {`Apply changes to ${gardenActivationTargetKeys.length} garden${gardenActivationTargetKeys.length === 1 ? '' : 's'}`}
                            <Select defaultValue='activate' onChange={(v) => setCommonWateringStatus(v)}>
                                <Select.Option value='active'>Activate</Select.Option>
                                <Select.Option value='disable'>Disable</Select.Option>
                            </Select>
                            <Button disabled={gardenActivationTargetKeys.length === 0} onClick={() => updateManyWateringStatus(gardenActivationTargetKeys, commonWateringStatus)}>Apply</Button>
                        </div> */}
                        </div>
                    </Modal>

                    {!selectedGarden && <div className="flex p-4 border rounded-md  gap-4 ">
                        <InfoLabel> Lütfen bir bahçe seçin </InfoLabel>
                    </div>}

                    {/* Start device assignment section */}

                    {/* Only roles above regional managers can add/remove devices to/from gardens */}

                    {hasAccess(ROLES.TECHNICAL_ADMIN, role) && selectedGarden && <div className="w-full pt-4">
                        <span className="block font-bold">Seçili bahçeye cihaz ekleyin</span>
                    </div>}

                    {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <div className="w-full flex flex-row">
                        {gardenDevicesAvailable.isLoading && <div className="w-full flex flex-col gap-5 animate-pulse">

                            <div className="w-full flex flex-row gap-5">
                                <div className="flex flex-col w-1/3 rounded-md gap-5">
                                    <div className="bg-gray-200 h-5 rounded-md"></div>
                                    <div className="bg-gray-200 h-32 rounded-md"></div>
                                </div>
                                <div className="flex flex-col w-1/3 rounded-md gap-5">
                                    <div className="bg-gray-200 h-5 rounded-md"></div>
                                    <div className="bg-gray-200 h-32 rounded-md"></div>
                                </div>
                            </div>
                        </div>}
                        {selectedGarden && !gardenDevicesAvailable.isLoading && <Transfer
                            dataSource={gardenDevicesAvailable.data}
                            showSearch
                            filterOption={filterOption}
                            targetKeys={targetKeys}
                            onChange={onTargetsChange}
                            render={(item) => item.Device?.name}
                            rowKey={(r) => r._id}
                            // listStyle={{ width: 180, height: 400 }}
                            locale={{ itemUnit: 'adet', itemsUnit: 'adet', searchPlaceholder: 'Arama' }}
                            titles={[
                                <div className="text-gray-800 font-bold text-xs">
                                    Seçilebilir
                                    <span className="text-gray-500 text-xs block font-normal">
                                        Boşta olan cihazlar
                                    </span>
                                </div>,
                                <div className="text-gray-800 font-bold text-xs">
                                    Atanmış
                                    <span className="text-gray-500 text-xs block font-normal">
                                        Bahçeye ait cihazlar
                                    </span>
                                </div>,
                            ]}
                        />}
                    </div>}

                    {/* End device assignment section */}

                    <div className="flex gap-3 pt-4">
                        <div className="block font-bold">{selectedGarden ? 'Parkı düzenle' : 'Yeni park ekle'} </div>
                        {selectedGarden && <button className=" text-blue-500 font-bold" onClick={() => { setSelectedGarden(null); setEditableGarden(null); }}>Yeni park ekle</button>}
                    </div>
                    <div className="flex flex-wrap gap-4  mb-4">
                        {!selectedGarden && <>
                            <div>
                                <Input allowClear value={newGardenName} onChange={(e) => { setNewGardenName(e.target.value) }} placeholder="MAHAL ADI" style={{ borderRadius: 5 }} className=" w-60"></Input>
                            </div>
                            <div>
                                <Button onClick={() => onGardenCreate()} style={{ borderRadius: 5 }}>{tr('Create')}</Button>
                            </div>
                        </>}

                        {editableGarden && <>
                            <div>
                                <Input value={editableGarden.id} placeholder="MAHAL ID" style={{ borderRadius: 5 }} disabled></Input>
                            </div>
                            <div>
                                <Input value={editableGarden.name} onChange={(e) => { setEditableGarden(p => ({ ...p, name: e.target.value })) }} placeholder="MAHAL ADI" style={{ borderRadius: 5 }}></Input>
                            </div>
                            {/* <div>
                                <Button onClick={() => { }} style={{ borderRadius: 5 }} disabled>{tr('Clear map')}</Button>
                            </div> */}
                            <div>
                                <Button onClick={() => { updateGarden.mutate(editableGarden) }} style={{ borderRadius: 5 }}>{tr('Save changes')}</Button>
                            </div>
                        </>}

                        <div className=" relative w-full">
                            <div className=" absolute left-1 bottom-1" style={{ zIndex: 401 }}>
                                <Select defaultValue={tenantSettings?.defaultBaseMap?.label || BASE_MAPS[2].label} onChange={onBaseMapChange}>
                                    {BASE_MAPS.map(m => <Option key={m.label} >{m.label}</Option>)}
                                </Select>
                            </div>
                            <MapContainer center={[0, 0]} zoom={12} scrollWheelZoom={true} style={{ height: '350px' }}>
                                <MapDetails garden={selectedGarden} baseMap={baseMap} onPolygonChange={onPolygonChange} polygonValues={newGardenPolygon} />

                            </MapContainer>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const MapDetails = ({ garden, baseMap, onPolygonChange, polygonValues }) => {
    const map = useMap()
    const geoRef = useRef()
    const tileRef = useRef()
    const [polygons, setPolygons] = useState([])
    const [geometry, setGeometry] = useState([])

    useEffect(() => {
        if (!polygonValues) {
            setGeometry([])
            map.eachLayer(l => {
                if (l.editing) {
                    map.removeLayer(l)
                }
            });
        }
    }, [polygonValues])

    useEffect(() => {
        if (garden === null) map.flyTo([process.env.REACT_APP_MAP_CENTER_LAT, process.env.REACT_APP_MAP_CENTER_LNG], parseInt(process.env.REACT_APP_MAP_ZOOM), { animate: true, duration: 1.2 });
    }, [])


    useEffect(() => {
        if (garden === null) return;
        // map.flyTo([41.084, 29.142205], 10, { animate: true, duration: 1.2 });

        // console.log('garden', garden);
        if (garden?.geoJson?.geometry?.coordinates) {
            const coords = garden.geoJson.geometry.coordinates.map(c => [...c.map(([lat, lng]) => [lng, lat])])
            setPolygons(coords)
        }


        if (geoRef.current) {
            const bounds = geoRef.current.getBounds();
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1.2,
            });
        }

    }, [garden])

    useEffect(() => {
        if (!geometry) return;

        onPolygonChange({
            type: geometry.length > 1 ? 'MultiPolygon' : 'Polygon',
            coordinates: geometry.length > 1 ? geometry.map(g => [g.g]) : geometry.map(g => g.g)
        })
    }, [geometry])

    const onEditPath = (e) => {
        e.layers.eachLayer((layer) => {
            setGeometry(p => p.map(g => (g.id === layer._leaflet_id ? { id: g.id, g: layer.toGeoJSON().geometry.coordinates[0] } : g)))
        });
    }

    const onCreate = (e) => {
        const type = e.layerType;
        const layer = e.layer;

        if (type === 'polygon') {
            setGeometry(p => [...p, { id: layer._leaflet_id, g: layer.toGeoJSON().geometry.coordinates[0] }])
        }
    }

    const onDeleted = (e) => {
        e.layers.eachLayer((layer) => {
            setGeometry(p => p.filter(g => g.id !== layer._leaflet_id))
        });

    }

    useEffect(() => {
        if (!baseMap) return;

        if (tileRef.current) {
            tileRef.current.setUrl(baseMap.value);
        }
    }, [baseMap])

    return <>
        <FeatureGroup>
            <EditControl
                position='topright'
                onEdited={onEditPath}
                onCreated={onCreate}
                onDeleted={onDeleted}
                draw={{
                    polygon: true,
                    marker: false,
                    polyline: false,
                    rectangle: false,
                    circle: false,
                    circlemarker: false,
                }}
            />
            {/* {polygons.map((p, i) => <Polygon key={i} positions={p} />)} */}
        </FeatureGroup>
        {garden && <GeoJSON style={(f) => (GeoJSON_STYLE)} key={garden.id} data={garden.geoJson} ref={geoRef}
        // onEachFeature={(feature, layer) => {
        //     layer.on('click', function (e) {
        //         e.target.editing.enable();
        //     })
        // }}
        />}

        <TileLayer maxNativeZoom={17} maxZoom={100} attribution={MAP_ATTRIBUTION_URL} url={baseMap?.value || BASE_MAPS[2].value} ref={tileRef} />
    </>
}

export default GardenAccess